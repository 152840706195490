import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/fbert/OneDrive/Documents/wwwBertramFamilyCom/GatsbyJS/src/components/layout-mdx.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e960c932f8ec95b5abaf06842ff3c086/e17e5/tom_med_hr.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADu0lEQVQ4y53Ua0ybZRQH8P/baqIfZ8gS/WIyRxaUm2yZMjrYEm6zYxQY9AaDrQHLLglxxjkdJjo1UZONrdBRoKWwtpRy6QXoCpZSCgXK1A2WgLIpMMlCHAxs395A9pia+JFS/Sfny8l5fjkfTh5gmwxIuej5JgO6S/GwyoSwK0VYn9dh1vYVRlRi/KeEMJf1OiwNZzBwi8+YNF6mfptSUJOGjxhz9+UIkseY6KyKDLPW8zDQWIb+egH6pTzKUluIYZUYU4NX4eq+CIe6khppOw9NdRqst3g7g53fctD+9WGYJQVU34089NfxMgabSk1O7QXjpOFShkNdCXtrOdV8MSYy0HyzEP11RYze6ydgvpHPsjYIyERHFfnZeY3cu3OFDDaWpFgbijGqrmREBH4v5cHeXMEclp+GrbGk1tX9JXnyyOZfWZ7yTw/LiF1RJnOqxBjTfcCMGHxgrmH+bq/FA/0VicOkJXMP7288mp/dGB/qJdPGTxtX7t3GL8OKyECrlI8Fh4Y5P9SKXweVx/6YtpHHMyNba0+mtp7OOsj8cEv2okOFRaeWMa4q3hm01wtAluYxNCTF7F0N1mdGzAsuC1l0Wcj6zEjP3KQGPzhbQJYWYPyuYGdwSZYDQgjcDjkjOKqEe1T52tMByfqKo2nV82Pnq5s/dcE91soIzTyU5YXHAloufH1n8RcZBa3jM91NWfDI2Vc98uPEZ64kdFvR57T+FLx9FcxNjx6+O+fg13K3B726YvhM5fB2lTK9Pe/D21ESTytOEFrJIX7bh8SrLnpOa7ixXoMIPv1ppr+vEr7OU2FALR++zlLK31GMDVsV/JbzxtW6zIk1RT6hbR9vrSnyxv3mswavKh/BXjH8hjOUt10YBmwXYrUhBx5tMdY1fAOxlNVE7X5DyM3NIWKRiMS9fZRPeoSSP9sEXW41D6uSDPg6SrYH6eZcBLpK8XzmGvVMxv6M3I3e9XpCem5KGtt9JD3XvSfp2HFi2xv1TMauJmQRAbMYtJITZsPWAgTauNg0iUAMHFSLipCXL2DzhRVOnqB8rLCwJPuLCh5Idy62rBcQ7BDCe/tkmA2VHARa87DcxEHX5fdejD6Yg2RW5sFDrEzjIVamKSU1e//edzjQf8J+YbmlCEHNSdAtYU6HvnkEQelR7Il6CQmxiVT0vnjEJbz7ZkzsAU3MWwfa4hOT94V6CXGJVPTul/+ZddekRfYvhg43VIlJyVms1CwVKzVLnbQ/Jf3f/v8Nk6IYrwCIChVFMXaFeuFe/A2GauKQ07mXMgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Tom Bertram",
            "title": "Tom Bertram",
            "src": "/static/e960c932f8ec95b5abaf06842ff3c086/e17e5/tom_med_hr.png",
            "srcSet": ["/static/e960c932f8ec95b5abaf06842ff3c086/5a46d/tom_med_hr.png 300w", "/static/e960c932f8ec95b5abaf06842ff3c086/e17e5/tom_med_hr.png 400w"],
            "sizes": "(max-width: 400px) 100vw, 400px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <br /><br />
    <center>
      <p>{`Tom hasn't set up his website yet. Maybe one day he will.`}</p>
      <p>{`In the meantime, see what the `}<a parentName="p" {...{
          "href": "/"
        }}>{`other family members`}</a>{` have to say.`}</p>
    </center>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      